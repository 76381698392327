import React from "react"

import useImages from "../hooks/useImages"
import useViewportSize, { SIZES } from "../hooks/useViewportSize"

import { pinHotel } from "../icons"

import Layout from "../components/layout"
import Map from "../components/map"
import Section from "../components/section"
import Separator from "../components/separator"

export default function Boda({ data }) {
  const { hero } = useImages()
  const viewportSize = useViewportSize()
  const heroContent = <></>
  const POIs = [
    {
      title: "Hotel Casona del Busto",
      icon: pinHotel,
      lat: 43.489381006592666,
      lng: -6.110478466275555,
      info: "http://www.hotelcasonadelbusto.com/",
      link: "https://goo.gl/maps/umEGLXVi6orvMSp3A",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMcJJ6rlleT8KUiNKBWCAoDoACMkGlQDy3lMjTQ=w408-h244-k-no",
    },
    {
      title: "Hotel Los Catorce",
      icon: pinHotel,
      lat: 43.488884675719845,
      lng: -6.10991474604622,
      info: "http://www.hotellos14.es/",
      link: "https://goo.gl/maps/THh4eRuhdAquyfqE8",
      img:
        "//lh6.googleusercontent.com/proxy/c0GfWVtJfownRlNv8YBjt90hndmvP2an5m-nedOyXMrDsGoUuQ-IXHfL-b4iUPgOxfR4fYxBz022bsFxKbl5jldSFyrT-j3Q2aIgP_h0GisSNZ29ryPj07CYHzrS0gvl1ckmYObRcNLvduuJrC4Wg7lxi9mRdQ=w408-h272-k-no",
    },
    {
      title: "Hotel Reina Adosinda",
      icon: pinHotel,
      lat: 43.48372520055091,
      lng: -6.1129343791431925,
      info: "https://www.hotelreinaadosinda.es/",
      link: "https://goo.gl/maps/7rPWEHhmHGM34MGs5",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipMe2j_ojrybo-Gnw4XehY3dP9DtUeNbDY5Ui-9o=w408-h270-k-no",
    },
    {
      title: "Hotel Piedras",
      icon: pinHotel,
      lat: 43.558632275525575,
      lng: -5.98165517303326,
      info: "https://www.hotelpiedras.com/",
      link: "https://goo.gl/maps/AyK8btugdr4MVzTcA",
      img:
        "https://lh3.googleusercontent.com/proxy/TGIDT2qsHxLOdApHt6TCD3k6tsMrrrNGRBNl4kXMuT6khTCds8q8smYDjCWnjCPfK3JzykHVqZ9cnkCSjq_azUF_0rQ9dkbJnA78RM-ukcR-bx5TSTMnJLBP1tJoOYK1Wpj_bjEtH1kZo5tpzX6egX18_eLp2Zc=w408-h270-k-no",
    },
    {
      title: "Hotel Oca Villa de Avilés",
      icon: pinHotel,
      lat: 43.55869605017645,
      lng: -5.929125879141423,
      info: "https://www.ocahotels.com/hoteles/hotel-oca-villa-de-aviles",
      link: "https://goo.gl/maps/ca1pQzu3BCjYbDXC8",
      img:
        "https://lh5.googleusercontent.com/proxy/bLZEGnz71fjcKIXXkeuczduX0pzUldi57y9KxO-OyxbQ3zG8qqcQrNWeHOvwpcNzfjS0nmnRSgzajQwa0DQf3gKIykNznKaN22GyfAVAAAHRzx3Xkpb64C8uMaWOYvUFRVbnz9NODByUncauZtVT-nAOrKcez24=w408-h248-k-no",
    },
    {
      title: "NH Palacio de Avilés",
      icon: pinHotel,
      lat: 43.55539683302173,
      lng: -5.921986831272297,
      info: "https://www.nh-hoteles.es/hotel/nh-collection-palacio-de-aviles",
      link: "https://g.page/NHCollectionPalaciodeAviles?share",
      img:
        "https://lh5.googleusercontent.com/p/AF1QipNpIBoJXvzIUwt0HYN0oouG0hk9HZYT3xrvJBei=w426-h240-k-no",
    },
  ]

  return (
    <Layout
      heroImg={hero.childImageSharp}
      heroContent={heroContent}
      heroHeight={viewportSize === SIZES.XS ? "100px" : "250px"}
    >
      <Section className={"text-block"}>
        <h2>Hoteles</h2>
        <Separator />
        <p>
          Aquí os dejamos una selección de hoteles que por su ubicación creemos
          que son buenas opciones.
        </p>
      </Section>

      <Map
        center={{
          lat: 43.4647896,
          lng: -5.7844736,
        }}
        zoom={10}
        POIs={POIs}
        selected={1}
      />
    </Layout>
  )
}
